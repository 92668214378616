.container {
    position: relative;
    width: 100%;
    height: 110vh;
    top: 100vh;
    left: 0;
    background-color: transparent;
}

.vitrin {
    position: absolute;
    right: 0;
    top: 1rem;
    width: 60vw;
    height: 100vh;
    background-image: url("../../images/stock1.jpeg");
    background-repeat: no-repeat;
    background-size: auto 100vh;
    mask-image: linear-gradient(285deg, #131313 40%, transparent 80%);
    mask-repeat: no-repeat;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transition: background-image 1.5s ease-in-out;
}

.logo {
    margin: 0;
    position: absolute;
    top: 20vh;
    left: 7%;
    transform: translate(-50%, -50%);
    width: 17.5rem;
    height: 17.5rem;
}

.container a {
    position: relative;
    top: 14%;
    left: 6.5%;
    z-index: 9;
    font-size: 2rem;
    font-family: "lexend exa";
    color: #fefefe;
}

.container .subText {
    font-size: 1.5rem;
    padding-left: 5rem;
}

.container .main {
    position: absolute;
    top: 15rem;
    width: 40rem;
    font-size: 1rem;
    line-height: 1.7rem;
}


@media screen and (max-width: 720px) and (orientation: portrait) {
    .container {
        height: 100vh;
        top: 140vh;
    }
    .logo {
        margin: 0;
        position: absolute;
        top: 15vh;
        left: 15%;
        transform: translate(-50%, -50%);
        width: 12rem;
        height: 12rem;
    }
    
    .container a {
        position: relative;
        top: 11vh;
        left: 8%;
        z-index: 9;
        font-size: 1.7rem;
        text-align: center;
        font-family: "lexend exa";
        color: #fefefe;
        padding-left: 1rem;
    }
    
    .container .subText {
        font-size: 1.2rem;
        padding-left: 0rem;
    }

    .container .main {
        position: relative;
        display: block;
        top: 67.5vw;
        width: 95vw;
        left: 0;
        font-size: 1rem;
        line-height: 1.7rem;
    }

    .vitrin {
        position: absolute;
        top: 12vh;
        width: 100vw;
        height: 55vw;
        background-repeat: no-repeat;
        background-size: 100vw auto;
        mask-image: linear-gradient(transparent, #0e0e0e 45%, #0e0e0e 85%, transparent);
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        transition: background-image 1.5s ease-in-out;
    }
}

@media screen and (max-height: 500px) and (orientation: landscape) {
    .logo {
        margin: 0;
        position: absolute;
        top: 7vh;
        left: 10vw;
        transform: translate(-50%, -50%);
        width: 13rem;
        height: 13rem;
    }
    
    .container a {
        position: relative;
        top: 0vh;
        left: 9vw;
        text-align: center;
        z-index: 9;
        font-size: 1.75rem;
        font-family: "lexend exa";
        color: #fefefe;
    }
    
    .container .subText {
        font-size: 1.3rem;
        padding-left: 0rem;
    }

    .vitrin {
        position: absolute;
        top: 10vh;
        width: 100vw;
        height: 50vh;
        background-position:0 -5vw;
        background-repeat: no-repeat;
        background-size: 100vw auto;
        mask-image: linear-gradient(355deg, #131313 30%, transparent 90%);
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        transition: background-image 1.5s ease-in-out;
    }

    .container .main {
        position: absolute;
        top: 65vh;
        width: 90vw;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1rem;
        line-height: 1.7rem;
        text-align: center;
    }
}