.container {
    position: relative;
    width: 100%;
    height: 100vh;
    top: 100vh;
    left: 0;
    background-color: transparent;
    overflow-x: hidden;
}

.container a {
    position: relative;
    top: 10%;
    left: 5rem;
    z-index: 9;
    font-size: 1.15rem;
    font-family: "lexend exa";
    color: #fefefe;
}

.container .subText {
    font-size: 2rem;
    padding-left: 1rem;
}

.container .rocket {
    top: 15%;
    right: 5rem;
    position: absolute;
    width: auto;
    height: 40rem;
}

.info {
    position: relative;
    width: 50%;
    height: 30rem;
    top: 11.5rem;
    left: 5.5rem;
    color: #fefefe;
    font-family: "lexend exa";
    font-size: 1.5vw;
    animation: none !important;
}

.infotab {
    display: inline-flex;
    height: 5vh;
    border-bottom: 0.1vw solid #fefefe;
    line-height: 5vh;
    margin-bottom: 1.5rem;
}

.variable {
    font-weight: 400;
    font-size: 1vw;
    width: 10vw;
}

.value {
    font-weight: 200;
    font-size: 1vw;
    width: 20vw;
    text-align: right;
}

@media screen and (max-width: 720px) and (orientation: portrait) {
    .container {
        height: 125vh;
        top: 200vh;
    }
    
    .container a {
        position: relative;
        top: 1vh;
        left: 7vw;
        z-index: 9;
        font-size: 1.7rem;
        text-align: center;
        font-family: "lexend exa";
        color: #fefefe;
        padding-left: 0rem;
    }
    
    .container .subText {
        font-size: 1.2rem;
        padding-left: 0rem;
    }

    .container .rocket {
        top: 12vh;
        right: 50%;
        transform: translate(50%, 0);
        position: absolute;
        width: 110vw;
        height: auto;
    }

    .info {
        position: relative;
        height: fit-content;
        top: 0vh;
        left: 5vw;
        transform: translate(0%, 350%);
        color: #fefefe;
        font-family: "lexend exa";
        animation: none !important;
    }

    .infotab {
        display: inline-flex;
        height: 5vh;
        border-bottom: 0.1vw solid #fefefe;
        line-height: 5vh;
        margin-bottom: 1.2rem;
        width: 90vw;
    }
    
    .variable {
        font-weight: 400;
        font-size: 1.1rem;
        width: 10vw;
    }
    
    .value {
        font-weight: 200;
        font-size: 1.1rem;
        width: 100vw;
        text-align: right;
        margin-left: 5rem;
    }

    #photo {
        height: 10vh;
    }
}

@media screen and (max-height: 500px) and (orientation: landscape) {
    .container {
        height: 125vh;
        top: 200vh;
    }

    .container a {
        position: relative;
        top: 5vh;
        left: 5vw;
        text-align: center;
        z-index: 9;
        font-size: 1.75rem;
        font-family: "lexend exa";
        color: #fefefe;
    }
    
    .container .subText {
        font-size: 1.3rem;
        padding-left: 0rem;
    }

    .container .rocket {
        top: 40%;
        right: 25%;
        transform: translate(50%, -50%);
        position: absolute;
        width: auto;
        height: 90vh;
    }

    .info {
        position: relative;
        width: 50vw;
        height: fit-content;
        top: 105.5vh;
        left: 5vw;
        transform: translate(0%, 0%);
        color: #fefefe;
        font-family: "lexend exa";
        animation: none !important;
    }

    .infotab {
        display: inline-flex;
        height: 7.5vh;
        border-bottom: 0.1vw solid #fefefe;
        line-height: 7.5vh;
        margin-bottom: 0.75rem;
    }
    
    .variable {
        font-weight: 400;
        font-size: 0.9rem;
        width: 10vw;
    }
    
    .value {
        font-weight: 200;
        font-size: 0.9rem;
        width: 25vw;
        text-align: right;
        margin-left: 5rem;
    }

    #photo {
        height: 15vh;
    }
}