.container {
    position: relative;
    top: 80vh;
    width: 100%;
    margin: 0;
    left: 0;
    height: 50vh;
}

.spon {
    width: 15vw;
    height: auto;
}

.label {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 5vh;
    font-size: 2rem;
    font-weight: 400;
}

.logos {
    top: 10vh;
    position: relative;
    width: 100vw;
    height: fit-content;
    left: 50vw;
    transform: translateX(-50vw);
    text-align: center;
}

.support {
    position: absolute;
    top: 40vh;
    width: 50vw;
    text-align: center;
    color: #e5b33e;
    font-weight: 300;
    font-size: 0.9rem;
    left: 50%;
    transform: translateX(-50%);
}


.ho {
    height: 100vh; 
    width: 100%;
    background: transparent;
    position: absolute;
    top: -30vh;
    z-index: -2; 
}
@media screen and (max-height: 500px) and (orientation: landscape) {
    .container {
        position: relative;
        top: 200vh;
        width: 100%;
        margin: 0;
        left: 0;
        height: 100vh;
    }
    
    .spon {
        width: 30vw;
        height: auto;
        margin: -1rem;
    }
    
    .label {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 5vh;
        font-size: 2rem;
        font-weight: 400;
    }
    
    .logos {
        top: 20vh;
        position: relative;
        width: 100vw;
        height: fit-content;
        left: 50vw;
        transform: translateX(-50vw);
        text-align: center;
    }
    
    .support {
        position: absolute;
        top: 80vh;
        width: 90vw;
        text-align: center;
        color: #e5b33e;
        font-weight: 300;
        font-size: 0.9rem;
        left: 50%;
        transform: translateX(-50%);
    }
    
    
    .ho {
        height: 100vh; 
        width: 100%;
        background: transparent;
        position: absolute;
        top: 0vh;
        z-index: -2; 
    }
}

@media screen and (max-width: 720px) and (orientation: portrait) {
    .ho {
        height: 100vh; 
        width: 100%;
        background: transparent;
        position: absolute;
        top: -30vh;
        z-index: -2; 
    }

    .container {
        position: relative;
        top: 250vh;
        width: 100%;
        margin: 0;
        left: 0;
        height: 50vh;
    }
    
    .spon {
        width: 60vw;
        height: auto;
        margin: -1rem;
    }
    
    .label {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -25vh;
        width: max-content;
        font-size: 10vw;
        font-weight: 400;
    }
    
    .logos {
        top: -15vh;
        position: relative;
        width: 100vw;
        height: fit-content;
        left: 50vw;
        transform: translateX(-50vw);
        text-align: center;
    }
    
    .support {
        position: absolute;
        top: 45vh;
        width: 90vw;
        text-align: center;
        color: #e5b33e;
        font-weight: 300;
        font-size: 1.0rem;
        left: 50%;
        transform: translateX(-50%);
    }
}