@import url('https://fonts.googleapis.com/css2?family=Lexend+Exa:wght@200;300;400&display=swap');
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    user-select: none;
}

ul {
    list-style-type: none;
}

a {
    color: #fefefe;
    text-decoration: none;
}

body {
    background-color: #111216;
    font-size: 1.2rem;
    line-height: 1.3;
    font-family: "lexend exa";
    font-weight: 200;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

svg {
    color: #fefefe;
}

html {
    scroll-behavior: smooth;
}