.page {
    position: relative;
    height: 100vh;
    width: 100%;
    margin: 0;
    top: 50vh;
}

.container {
    position: relative;
    height: 100vh;
    width: 100%;
    margin: 0;
}

.spacer {
    display: none;
}

.container .showcase {
    top: 15%;
    right: 5rem;
    position: absolute;
    height: auto;
    width: 40rem;
    background-position: center;
}

.container a {
    position: relative;
    top: 14%;
    left: 6.5%;
    z-index: 9;
    font-size: 2rem;
    font-family: "lexend exa";
    color: #fefefe;
}

.container .subText {
    font-size: 1.5rem;
    padding-left: 0rem;
}

.container .main {
    position: absolute;
    top: 15rem;
    width: 40rem;
    font-size: 1rem;
    line-height: 1.7rem;
}

@media screen and (max-width: 720px) and (orientation: portrait) {
    .page {
        position: relative;
        height: 100vh;
        width: 100%;
        margin: 0;
        top: 200vh;
    }

    .container .showcase {
        top: 12vh;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        height: auto;
        width: 80vw;
        background-position: center;
    }
    
    .spacer {
        display: block;
    }

    .container a {
        position: relative;
        top: 1vh;
        left: 5vw;
        z-index: 9;
        font-size: 1.5rem;
        font-family: "lexend exa";
        color: #fefefe;
    }
    
    .container .subText {
        font-size: 1.3rem;
        padding-left: 0rem;
    }
    
    .container .main {
        position: absolute;
        top: 45vh;
        width: 80vw;
        font-size: 1.1rem;
        line-height: 1.9rem;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media screen and (max-height: 500px) and (orientation: landscape) {
    .page {
        position: relative;
        height: 100vh;
        width: 100%;
        margin: 0;
        top: 200vh;
    }

    .container .showcase {
        top: 50%;
        right: 25%;
        transform: translate(50%, -50%);
        position: absolute;
        width: 40vw;
        height: auto;
    }
    
    .spacer {
        display: none;
    }

    .container a {
        position: relative;
        top: 5vh;
        left: 5vw;
        z-index: 9;
        font-size: 1.5rem;
        font-family: "lexend exa";
        color: #fefefe;
    }
    
    .container .subText {
        font-size: 1.3rem;
        padding-left: 0rem;
    }
    
    .container .main {
        position: absolute;
        top: 25vh;
        width: 40vw;
        font-size: 0.8rem;
        line-height: 1.5rem;
        text-align: left;
        left: 7.5vw;
    }
}