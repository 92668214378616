.navbar {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    align-content:center;
}

.menu {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 1rem;
}

.menu li {
    padding: 0.8rem;
}

.navbar a {
    font-size: .9rem;
    transition: border-color 0.2s ease-in-out;
    padding-bottom: 5px;
    border-bottom: 1.5px solid transparent;
    cursor: pointer;
}

.navbar a:hover {
    border-color: #fefefe;
}

.join {
    /*pointer-events: none !important;
    color: #8a8a8a;*/
}

.mobile_btn {
    display: none;
}

@keyframes bounce {
    0% {bottom: 0.75rem;}
    15% {bottom: 1.25rem;}
    30% {bottom: 0.75rem;}
    100% {bottom: 0.75rem;}
}

.down svg{
    font-size: 1.5rem;
    color: #ffffff;
    position: fixed;
    bottom: 0.75rem;
    left: 50%;
    transform: translateX(-50%);
    animation: bounce 3s infinite ease-in-out;
}

@media screen and (max-width: 720px) and (orientation: portrait) {
    .mobile_btn {
        display: block;
        position: absolute;
        right: 1.5rem;
        top: 1.25rem;
        cursor: pointer;
        z-index: 10;        
    }

    .mobile_btn a {
        font-size: 2rem;
        color:#111216;
    }

    .menu {
        display: block;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        left: -100%;
        right: 0;
        bottom: 0;
        top: 0;
        padding-top: 5rem;
        padding-left: 2rem;
        width: 100%;
        height: 100vh;

        background-color: #fefefe;
        z-index: 10;
        transition: left 1s;
    }
    
    .active {
        left: 0;
    }

    .menu li {
        padding: 1.2rem;
    }

    .menu li a {
        color:#111216;
    }

    .navbar a {
        font-size: 1.5rem;
        font-weight: 400;
    }

    .navbar a svg {
        font-size: 1.75rem;
    }
}

@media screen and (max-height: 500px) and (orientation: landscape) {
    .mobile_btn {
        display: block;
        position: absolute;
        right: 1.5rem;
        top: 1.25rem;
        cursor: pointer;
        z-index: 10;        
    }

    .mobile_btn a {
        font-size: 2rem;
        color:#111216;
    }

    .menu {
        display: block;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        overflow-y: hidden;
        left: -100%;
        right: 0;
        bottom: 0;
        top: 0;
        padding-top: 3rem;
        padding-left: 2rem;
        width: 100%;
        height: 100vh;

        background-color: #fefefe;
        z-index: 10;
        transition: left 1s;
    }
    
    .active {
        left: 0;
    }

    .menu li {
        padding: 1rem;
    }

    .menu li a {
        color:#111216;
    }

    .navbar a {
        font-size: 1.5rem;
        font-weight: 400;
    }

    .navbar a svg {
        font-size: 1.75rem;
    }
}

.hero {
    display: flex;
    width: 100%;
    height: 110vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    mask-image: linear-gradient(#0e0e0e 80%, transparent);
    transition: filter 1s;
} 

.blur {
    filter: blur(5px);
}

.name {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    font-size: 2rem;
    font-family: "lexend exa";
    color: #fefefe;
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.name h1 {
    padding: 0.5rem;
    z-index: 6;
}

.logo {
    margin: 0;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    height: 30rem;
    z-index: 0;
}

@media screen and (max-width: 720px) and (orientation: portrait) {
    .name {
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.1rem;
    }
    
    .name h1 {
        padding: 0.2rem;
    }
    
    .logo {
        width: 22.5rem;
        height: 22.5rem;
    }

    .hero .down svg {
        font-size: 1.5rem;
    }
}

@media screen and (max-height: 500px) and (orientation: landscape) {
    .name {
        font-size: 1.1rem;
    }
    
    .name h1 {
        padding: 0.2rem;
    }
    
    .logo {
        width: 22.5rem;
        height: 22.5rem;
    }
}