.container {
    position: relative;
    top: 100vh;
    width: 100%;
    margin: 0;
    left: 0;
    height: 10rem;
    overflow: hidden;
    border-top: 1px solid #fefefe30;
}

.copy {
    position: absolute;
    bottom: 2vh;
    width: 50vw;
    text-align: center;
    color: #fefefe60;
    font-weight: 300;
    font-size: 0.8rem;
    left: 50%;
    transform: translateX(-50%);
}

.socials {
    position: absolute;
    width: 17.5rem;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    height: fit-content;
    font-size: 2.5rem;
}

.contact {
    position: absolute;
    left: 2rem;
    width: 20vw;
    top: 1.45rem;
    text-align: left;
    height: fit-content;
    font-size: 2rem;
    margin-bottom: 0.1rem;
}

.email {
    position: absolute;
    font-size: 1rem;
}

.email svg {
    font-size: 1.5rem;
    position: relative;
    top: 50%;
    transform: translateY(27.5%);
}

@media screen and (max-height: 500px) and (orientation: landscape) {
    .container {
        position: relative;
        top: 200vh;
        width: 100%;
        margin: 0;
        left: 0;
        height: 10rem;
        overflow: hidden;
        border-top: 1px solid #fefefe30;
    }
    
    .copy {
        position: absolute;
        bottom: 2vh;
        width: 50vw;
        text-align: center;
        color: #fefefe60;
        font-weight: 300;
        font-size: 0.8rem;
        left: 50%;
        transform: translateX(-50%);
    }
    
    .socials {
        position: absolute;
        width: 17.5rem;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        height: fit-content;
        font-size: 2.5rem;
    }
    
    .contact {
        position: absolute;
        left: 2rem;
        width: 20vw;
        top: 1.45rem;
        text-align: left;
        height: fit-content;
        font-size: 2rem;
        margin-bottom: 0.1rem;
    }
    
    .email {
        position: absolute;
        font-size: 1rem;
        width: 50vw;
    }
    
    .email svg {
        font-size: 1.5rem;
        position: relative;
        top: 50%;
        transform: translateY(27.5%);
    }
}

@media screen and (max-width: 720px) and (orientation: portrait) {
    .container {
        position: relative;
        top: 270vh;
        width: 100%;
        margin: 0;
        left: 0;
        height: 15rem;
        overflow: hidden;
        border-top: 1px solid #fefefe30;
    }
    
    .copy {
        position: absolute;
        bottom: 2vh;
        width: 50vw;
        text-align: center;
        color: #fefefe60;
        font-weight: 300;
        font-size: 0.8rem;
        left: 50%;
        transform: translateX(-50%);
    }
    
    .socials {
        position: absolute;
        width: 17.5rem;
        right: 50%;
        top: 75%;
        transform: translate(50%, -75%);
        text-align: center;
        height: -moz-fit-content;
        height: fit-content;
        font-size: 2.5rem;
    }
    
    .contact {
        position: absolute;
        left: 2rem;
        width: 20vw;
        top: 1.45rem;
        text-align: left;
        height: fit-content;
        font-size: 2rem;
        margin-bottom: 0.1rem;
    }
    
    .email {
        position: absolute;
        font-size: 1rem;
        width: 100vw;
    }
    
    .email svg {
        font-size: 1.5rem;
        position: relative;
        top: 50%;
        transform: translateY(27.5%);
    }
}